import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Credentials } from "../Models/Credentials.Model";
import {
  updateAuthentication,
  updateTokens,
} from "../Redux/Slices/Profile.slice";
import { showToast } from "../Redux/Slices/Toast.slice";
import UseApiService, { API_URL, IDataObject } from "../services/api.service";
import {
  clearLocalStorageIfNoCredentials,
  handleError,
} from "../utils/helpers";
import { StorageEnum } from "../utils/storage.enums";

export default function Layout(): JSX.Element {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const fetchNewCredentials = async () => {
    try {
      const cred: any = localStorage.getItem(StorageEnum.CREDENTIALS);
      if (!cred) {
        //logout
        clearLocalStorageIfNoCredentials();
        return;
      }
      let credentials: Credentials = JSON.parse(cred);
      const obj: IDataObject = {
        url: API_URL.REFRESH_TOKEN,
        data: credentials,
      };
      const res = await UseApiService().post(obj);
      credentials = new Credentials(res.data);
      dispatch(updateTokens({ ...credentials }));
      localStorage.setItem(
        StorageEnum.CREDENTIALS,
        JSON.stringify(credentials)
      );
    } catch (error) {
      logout();
      dispatch(showToast(handleError(error)));
    }
  };

  const logout = () => {
    clearLocalStorageIfNoCredentials();
    navigate("/");
  };

  useEffect(() => {
    dispatch(
      updateAuthentication(!!localStorage.getItem(StorageEnum.CREDENTIALS))
    );
  }, [location]);

  useEffect(() => {
    fetchNewCredentials();
  }, []);

  return <Outlet />;
}
