import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideToast } from "../../Redux/Slices/Toast.slice";
import { StoreInterface } from "../../Redux/Store";
import success from "../../assets/images/success-icon.svg";
import warning from "../../assets/images/warning-icon.svg";
import style from "./Snackbar.module.scss";

export default function Snackbar() {
  const { toast } = useSelector((store: StoreInterface) => store.toast);
  const dispatch = useDispatch();
  const toastRef = useRef<NodeJS.Timeout | undefined>();
  useEffect(() => {
    clearTimeout(toastRef?.current);

    toastRef.current = setTimeout(() => {
      dispatch(hideToast());
    }, 5000);
  }, [toast, dispatch]);

  return (
    <div
      className={`${style.snackbar} ${
        toast ? style.active : ""
      } flex alignCenter `}
    >
      {toast &&
        (toast.includes("success") ? (
          <img src={success} alt="success" />
        ) : (
          <img src={warning} alt="warning" />
        ))}

      <span>{toast}</span>
    </div>
  );
}
