import { yupResolver } from "@hookform/resolvers/yup";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { UserModel } from "../../Models/UserModel";
import { showToast } from "../../Redux/Slices/Toast.slice";
import Input from "../../components/input/Input";

import searchIcon from "../../assets/images/search-icon.svg";

import { DatePicker } from "antd";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import style from "../../assets/scss/dataList.module.scss";
import Pagination from "../../components/pagination/pagination";
import UseApiService, {
  API_URL,
  IDataObject,
  TokenData,
} from "../../services/api.service";
import { handleError } from "../../utils/helpers";
import { searchUsersSchema } from "../../utils/schema";
const { RangePicker } = DatePicker;

export function ListOfUsers() {
  const { register, watch, setValue, getValues } = useForm({
    resolver: yupResolver(searchUsersSchema),
  });
  const searchDebounceRef = useRef<any>();
  const searchValueRef = useRef<string>("");

  useEffect(() => {
    const subscription = watch((value) => {
      searchUsers((value?.search ?? "").trim(), value.startDate, value.endDate);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const limit = 20;
  const [users, setUsers] = useState<UserModel[]>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    getUsers(1, "");
  }, []);

  const searchUsers = (
    searchString: string,
    startDate: string = "",
    endDate: string = ""
  ) => {
    searchDebounceRef?.current?.cancel();
    searchDebounceRef.current = debounce(() => {
      getUsers(1, searchString, startDate, endDate);
    }, 300);
    searchDebounceRef.current();
  };

  const getUsers = async (
    page = 1,
    search = "",
    startDate = "",
    endDate = ""
  ) => {
    const obj: IDataObject = {
      url: API_URL.USER,
      data: {
        page,
        search,
        limit,
        startDate,
        endDate,
        isVerified: false,
      },
      headerToken: TokenData.ACCESS,
    };
    try {
      setLoading(true);
      const res = await UseApiService().get(obj);
      searchValueRef.current = search.trim();
      setCount(res.data.count);
      setLoading(false);
      setUsers(res.data.users.map((_: UserModel) => new UserModel(_)));
      setPage(page);
      setSearch(search);
    } catch (error: any) {
      setLoading(false);
      dispatch(showToast(handleError(error)));
    }
  };

  const onChange = (date: any, dateString: any) => {
    setValue("startDate", dateString[0]);
    setValue("endDate", dateString[1]);
  };

  return (
    <div className={` ${style.userList}`}>
      <div
        className={` ${style.userList__header} flex alignCenter justifyBetween`}
      >
        <h2>Users</h2>
        <div className={style.userList__wrapper}>
          <RangePicker
            onChange={onChange}
            className={style.userList__wrapper__date_picker}
          />
          <div className={style.userList__input}>
            <div
              className={`${style.userList__search} flex alignCenter justifyCenter`}
            >
              <img src={searchIcon} alt="search" />
            </div>
            <Input
              type="text"
              register={register}
              name="search"
              placeholder="Search..."
              autocomplete={"off"}
            />
          </div>
        </div>
      </div>
      <div className={style.userList__table}>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Created at</th>
              <th>Verified</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              new Array(7).fill(20).map((el, index) => {
                return (
                  <tr key={index} className={style.skeletonRow}>
                    <td>
                      <Skeleton width={"70%"} />
                    </td>
                    <td>
                      <Skeleton width={"70%"} />
                    </td>
                    <td>
                      <Skeleton width={"70%"} />
                    </td>
                    <td>
                      <Skeleton width={100} />
                    </td>
                  </tr>
                );
              })
            ) : users.length === 0 ? (
              <tr>
                <td colSpan={4} className={style.userList__noData}>
                  No data found
                </td>
              </tr>
            ) : (
              users.map((el, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Link to={"/user/" + el.id}>
                        {el.firstName + " " + el.lastName}
                      </Link>
                    </td>
                    <td>{el.email}</td>
                    <td>{el.createdAt}</td>
                    <td>{el.accountVerified ? "YES" : "NO"}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        count={count}
        limit={limit}
        currentPage={page}
        selectPage={(page: number) =>
          getUsers(page, search, getValues("startDate"), getValues("endDate"))
        }
      />
    </div>
  );
}
