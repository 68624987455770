import { createSlice } from "@reduxjs/toolkit";
import { UserModel } from "../../Models/UserModel";
import { StorageEnum } from "../../utils/storage.enums";

export interface ProfileState {
  accessToken: string;
  refreshToken: string;
  currentUser: UserModel;
  isAuthenticated: boolean;
}

let initialState = {
  isAuthenticated: !!localStorage.getItem(StorageEnum.CREDENTIALS)
} as ProfileState;
export const profileReducer = createSlice({
  name: "profile",
  initialState,
  reducers: {
    editProfile: editProfileHandler,
    updateTokens: updateTokensHandler,
    updateAuthentication: updateAuthenticationHandler,
  },
});

function updateTokensHandler(
  state: ProfileState,
  action: {
    payload: { accessToken: string; refreshToken: string };
    type: string;
  }
) {
  return {
    ...state,
    accessToken: action.payload.accessToken ?? state.accessToken,
    refreshToken: action?.payload?.refreshToken ?? state.refreshToken,
  };
}

function editProfileHandler(
  state: ProfileState,
  action: {
    payload: { currentUser: UserModel };
    type: string;
  }
) {
  return {
    ...state,
    currentUser: action.payload.currentUser
      ? action.payload.currentUser
      : state.currentUser,
  };
}

function updateAuthenticationHandler(
  state: ProfileState,
  action: {
    payload: boolean;
  }
) {
  return {
    ...state,
    isAuthenticated: action.payload,
  };
}
export const { updateTokens, editProfile, updateAuthentication } =
  profileReducer.actions;
