import { useRef, useState } from "react";
import { FieldErrors, FieldValues } from "react-hook-form";
import eyeCross from "../../assets/images/eye-close.svg";
import eye from "../../assets/images/eye-open.svg";
import style from "../../assets/scss/form.module.scss";

export interface InputProps {
  name: string;
  label?: string;
  placeholder: string;
  errors?: FieldErrors<FieldValues | any>;
  register: any;
  type: "text" | "password";
  autocomplete?: "on" | "off";
  extraClass?: string | undefined;
  required?: boolean;
  readOnly?: boolean;
}

export default function Input(props: InputProps) {
  const {
    register,
    name,
    label,
    errors,
    type,
    placeholder,
    autocomplete = "off",
    extraClass,
    required = false,
    readOnly = false
  } = props;
  const [show, setShow] = useState(false);

  return (
    <div className={`${style.inputField} ${extraClass || ""}`}>
      {label && (
        <label className={`flex`}>
          {label}
          {required && <span>*</span>}
        </label>
      )}
      <div
        className={`${style.inputField__input} ${
          type === "password" ? style.inputField__inputIcon : ""
        }`}
      >
        <input
          {...register(name)}
          type={show ? "text" : type}
          placeholder={placeholder}
          autoComplete={autocomplete}
          readOnly={readOnly}
        />
        {type === "password" && (
          <img
            src={show ? eyeCross : eye}
            alt="Eye"
            onClick={() => setShow(!show)}
          />
        )}
      </div>

      {errors?.[name] && (
        <div className={style.inputField__error}>
          {errors?.[name]?.message as string}
        </div>
      )}
    </div>
  );
}
