import { legacy_createStore as createStore, Store } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { profileReducer, ProfileState } from "./Slices/Profile.slice";
import { toastReducer, ToastState } from "./Slices/Toast.slice";

export interface StoreInterface {
  profile: ProfileState;
  toast: ToastState;
}

const reducer = combineReducers({
  profile: profileReducer.reducer,
  toast: toastReducer.reducer,
});

export const store: Store<StoreInterface> = createStore(reducer);
