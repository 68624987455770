import { Provider } from "react-redux";
import Routing from "./pages/routing";
import "./styles.scss";

import { store } from "./Redux/Store";
import { interceptor } from "./services/interceptor";

function App() {
  interceptor();
  return (
    <Provider store={store}>
      <Routing />
    </Provider>
  );
}

export default App;
