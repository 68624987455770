export enum ErrorMessages {
  REQUIRED = "This field is required",
  VALID_EMAIL = "Please enter a valid email",
  PASSWORD_REGEX = "Password must contain minimum one uppercase, lowercase and special character",
  PASSWORD_NOT_MATCHING = "Passwords do not match",
  CODE_LENGTH = "Code must be 6 characters long",
}

export enum SuccessMessages {
  LOGGED_IN = "Logged in successfully",
  CODE_SENT = "Code sent successfully",
  PASSWORD_RESET_SUCCESSFULLY = "Password reset successfully, please try logging in again.",
  ACCOUNT_UPDATED_SUCCESSFULLY = "User account details updated successfully",
  ACCOUNT_FREEZE_SUCCESSFUL = "Account froze successfully",
  ACCOUNT_UNFREEZE_SUCCESSFUL = "Account unfroze successfully",
}

export enum ConfirmationMessages {
  DELETE_SHOPPING_ITEM = "Are you sure you want to delete this item?",
}
