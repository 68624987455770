import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../Redux/Slices/Toast.slice";
import style from "../../assets/scss/auth.module.scss";
import AuthWrapper from "../../components/AuthWrapper/AuthWrapper";
import CustomButton from "../../components/Button/Button";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import Input from "../../components/input/Input";
import UseApiService, { API_URL } from "../../services/api.service";
import { SuccessMessages } from "../../utils/errors.enums";
import { handleError } from "../../utils/helpers";
import { resetPasswordSchema } from "../../utils/schema";

export default function EnterCode() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const resetPassword = async (data: FieldValues) => {
    try {
      const obj = {
        url: API_URL.RESET_PASSWORD,
        data: data,
      };
      setLoading(true);
      await UseApiService().post(obj);
      dispatch(showToast(SuccessMessages.PASSWORD_RESET_SUCCESSFULLY));
      navigate("/", { replace: true });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(showToast(handleError(error)));
    }
  };

  return (
    <AuthWrapper>
      <>
        <h1>Reset your password</h1>
        <form onSubmit={handleSubmit(resetPassword)}>
          <Input
            label={"Code"}
            register={register}
            name={"uniqueCode"}
            type="text"
            errors={errors}
            placeholder="Enter Code"
            required={true}
          />

          <Input
            label={"New Password"}
            register={register}
            name={"newPassword"}
            type="password"
            errors={errors}
            placeholder="Enter New Password"
            required={true}
          />

          <div className={` ${style.login__actions} flexWrap`}>
            <CustomButton
              title="RESET PASSWORD"
              type="submit"
              disabled={loading}
              onClick={handleSubmit(resetPassword)}
            >
              {loading && <ButtonLoader />}
            </CustomButton>
          </div>
        </form>
      </>
    </AuthWrapper>
  );
}
