import { FieldErrors, FieldValues } from "react-hook-form";
import MaskedInput from "react-input-mask";
import style from "../../assets/scss/form.module.scss";

export interface InputProps {
  name: string;
  label?: string;
  placeholder: string;
  errors?: FieldErrors<FieldValues | any>;
  register: any;
  type: "text" | "password";
  autocomplete?: "on" | "off";
  extraClass?: string | undefined;
  required?: boolean;
}

export default function PhoneInput(props: InputProps) {
  const {
    register,
    name,
    label,
    errors,
    extraClass,
    placeholder,
    required = false,
  } = props;

  return (
    <div className={`${style.inputField} ${extraClass || ""}`}>
      {label && (
        <label className={`flex`}>
          {label}
          {required && <span>*</span>}
        </label>
      )}
      <div className={`${style.inputField__input}`}>
        <MaskedInput
          mask={"(999) 999-9999"}
          alwaysShowMask={false}
          aria-placeholder={placeholder}
          maskChar={" "}
          type={"text"}
          placeholder={placeholder}
          {...register(name)}
        />
      </div>

      {errors?.[name] && (
        <div className={style.inputField__error}>
          {errors?.[name]?.message as string}
        </div>
      )}
    </div>
  );
}
