export function handleError(error: any): string {
  const errorMessage = error?.response?.data?.message;
  if (errorMessage && typeof errorMessage === "string") {
    return errorMessage;
  } else if (
    errorMessage &&
    typeof errorMessage === "object" &&
    errorMessage.length > 0
  ) {
    return errorMessage[0];
  }
  return (
    error?.response?.data?.message ?? error?.message ?? "Something went wrong"
  );
}

export const clearLocalStorageIfNoCredentials = () => {
  localStorage.clear();
};
