import { createSlice } from "@reduxjs/toolkit";

export interface ToastState {
  toast: string;
}

let initialState = {
  toast: "",
} as ToastState;

export const toastReducer = createSlice({
  name: "toast",
  initialState,
  reducers: {
    hideToast: hideToastHandler,
    showToast: showToastHandler,
  },
});

function showToastHandler(
  state: ToastState,
  action: {
    payload: string;
  }
) {
  return {
    ...state,
    toast: action.payload,
  };
}

function hideToastHandler(state: ToastState) {
  return {
    ...state,
    toast: "",
  };
}

export const { showToast, hideToast } = toastReducer.actions;
