import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { ShoppingCartModel } from "../../Models/ShoppingCart.Model";
import { showToast } from "../../Redux/Slices/Toast.slice";
import Back from "../../assets/images/back-arrow.svg";
import Delete from "../../assets/images/delete.svg";
import style from "../../assets/scss/dataList.module.scss";
import Modal from "../../components/Modal/Modal";
import Pagination from "../../components/pagination/pagination";
import UseApiService, {
  API_URL,
  IDataObject,
  TokenData,
} from "../../services/api.service";
import { ConfirmationMessages } from "../../utils/errors.enums";
import { handleError } from "../../utils/helpers";

const UserShoppingList = () => {
  let { userId } = useParams();
  const dispatch = useDispatch();
  const limit = 20;
  const [count, setCount] = useState<number>(0);
  const [shoppingListData, setShoppingListData] = useState<ShoppingCartModel[]>(
    []
  );
  const [deleteId, setDeleteId] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const parentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // check if userId is a valid number
    if (userId && isNaN(+userId)) {
      navigate("/user/list");
    } else if (userId) {
      getShoppingDetails();
    }
  }, [userId]);

  const getShoppingDetails = async (page = 1) => {
    const obj: IDataObject = {
      url: API_URL.SHOPPING_LIST + "/" + userId,
      data: {
        page,
        limit,
      },
      headerToken: TokenData.ACCESS,
    };

    try {
      setLoading(true);
      const res = await UseApiService().get(obj);
      setCount(res.data.count);
      setLoading(false);
      setShoppingListData(
        res.data.shoppingCart.map((el: any) => new ShoppingCartModel(el))
      );
      setPage(page);
    } catch (error: any) {
      setLoading(false);
      dispatch(showToast(handleError(error)));
    }
  };

  const deleteItem = async (id: string) => {
    noResponse();
    const obj: IDataObject = {
      url: API_URL.DELETE_ITEM,
      data: {
        id,
      },
    };

    try {
      await UseApiService().delete(obj);
      await getShoppingDetails(page);
    } catch (error: any) {
      setLoading(false);
      dispatch(showToast(handleError(error)));
    }
  };

  const openModal = (id: string): void => {
    setDeleteId(id);
    if (parentRef.current) {
      parentRef.current.classList.remove("d-none");
    }
  };

  const noResponse = (): void => {
    parentRef.current?.classList.add("d-none");
  };

  const goBack = () => {
    navigate("/user/" + userId, { replace: true });
  };

  return (
    <>
      <div className={` ${style.userList}`}>
        <h2 className="flex alignCenter">
          <img src={Back} alt="Back arrow" onClick={goBack} />
          <span>User Shopping Record</span>
        </h2>
        <div className={style.userList__table}>
          <table>
            <thead>
              <tr>
                <th>Category</th>
                <th>Item</th>
                <th>Brand</th>
                <th>Size</th>
                <th>Price</th>
                <th>Date Purchased</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                new Array(7).fill(null).map((el, index) => {
                  return (
                    <tr key={index} className={style.skeletonRow}>
                      <td>
                        <Skeleton width={"60%"} />
                      </td>
                      <td>
                        <Skeleton width={"60%"} />
                      </td>
                      <td>
                        <Skeleton width={"60%"} />
                      </td>
                      <td>
                        <Skeleton width={"60%"} />
                      </td>
                      <td>
                        <Skeleton width={"60%"} />
                      </td>
                      <td>
                        <Skeleton width={"60%"} />
                      </td>
                      <td>
                        <Skeleton width={"60%"} />
                      </td>
                    </tr>
                  );
                })
              ) : shoppingListData.length === 0 ? (
                <tr>
                  <td colSpan={7} className={style.userList__noData}>
                    No data found
                  </td>
                </tr>
              ) : (
                shoppingListData.map((e: ShoppingCartModel, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{e.category}</td>
                      <td>{e.item}</td>
                      <td>{e.brand}</td>
                      <td>{e.size}</td>
                      <td>{e.price}</td>
                      <td>{e.datePurchased}</td>
                      <td>
                        <img
                          onClick={() => openModal(e.id)}
                          src={Delete}
                          alt="Delete"
                        />
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>

        <Modal
          ref={parentRef}
          yesResponse={() => deleteItem(deleteId)}
          noResponse={() => noResponse()}
        >
          <p>{ConfirmationMessages.DELETE_SHOPPING_ITEM}</p>
        </Modal>

        <Pagination
          count={count}
          limit={limit}
          currentPage={page}
          selectPage={(page: number) => getShoppingDetails(page)}
        />
      </div>
    </>
  );
};

export default UserShoppingList;
