import { StorageEnum } from "../utils/storage.enums";

export let getToken = async (data: string) => {
  let profile: any = localStorage.getItem(StorageEnum.CREDENTIALS);
  profile = JSON.parse(profile);
  return (
    "Bearer " +
    (data === "ACCESS" ? profile?.accessToken : profile?.refreshToken)
  );
};
